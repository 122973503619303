import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/key-resources/bc_1_3.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_3.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_3.png");

export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Workativ Assistant: Chatbot Best Practices for designing a conversational experience CTA: Read Blog",
    url:
      "https://blog.workativ.com/chatbot-best-practices-for-designing-a-conversational-experience-with-workativ-assistant/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header: "Untethering human resources from mindless and repetitive tasks.",
    url: "/conversational-ai-platform/impact-of-AI",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header:
      "Deflect calls away from the helpdesk by deploying an AI-powered chatbot.",
    url: "https://youtu.be/Gbx-MXB73uU",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Self-service automation",
    content:
      "A step above general automation by implementing next-gen technology, providing better workplace support for the modern workforce.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "AI Self-service through Workativ Assistant",
    content:
      "An intelligent conversational assistant that helps employees self-resolve issues without a slip in productivity.",
    backgroundClass: "card_two"
  },
  {
    href: "#section-3",
    header: "How does Workativ Assistant make your work more efficient?",
    content:
      "Our product provides better and modern self-service led by AI and automation.",
    backgroundClass: "card_three"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="AI Self-Service Chatbot Experience for Employees | Workativ Assistant Self-Service"
        description="Create a modern AI self-service experience for employees by implementing next-gen Digital Assistant for better workplace support."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="AI Self-Service Chatbot Experience for Employees | Workativ Assistant Self-Service"
        ogDescription="Create a modern AI self-service experience for employees by implementing next-gen Digital Assistant for better workplace support."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div>
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Self-service automation
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    Provide a modern, AI self-service experience to your
                    employees
                  </TopFormWithCenter.Header>

                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
                
              </section>
              <section className="wv-section-mv-p pb-200 content_para">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4 mx-auto">
                        Self-service is not a new concept. It has been adopted
                        in ITSM to replace back and forth emails to the help
                        desk and typically works with an updated knowledge base
                        to help employees find answers. The static self-service
                        models employed in organizations merely exist to help
                        employees seek assistance and resolve issues, but, they
                        are slow resolution systems.
                      </p>
                      <hr className="wv-limited-content wv-section-mv-m mx-auto mt-5 mb-5" />
                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mx-auto mb-3">
                        Slow processes cause frustration. The static nature of a
                        traditional helpdesk does not comply with the
                        expectations of a tech-savvy user and leaves a lot to be
                        desired. A more recent self-service model would have
                        options that enable employees to resolve issues faster
                        and reduce pressure on the IT support staff. Many
                        requests have become repetitive and can be automated to
                        save time with faster management and execution.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <Cards cards={cardData} />
              <section className="bg_section_knowledge self_service_an">
                <div className="container">
                  <div className="row">
                    <div className="col pr-0 pl-0  -call-reflection">
                      <div className="">
                        <div className="main-slider-left">
                          <h1>
                            A self-service model that enables employees to
                            resolve issues faster and reduce pressure on the IT
                            support staff.
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            <AnchorLink
                              offset={240}
                              className="url_manipulation"
                              href="#section-1"
                            >
                              DIY assistance
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={240}
                              className="url_manipulation"
                              href="#section-2"
                            >
                              Instant resolution
                            </AnchorLink>
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5">
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive === " DIY assistance"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(" DIY assistance");
                              }}
                            >
                              {" "}
                              DIY assistance
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive === "Instant resolution"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("Instant resolution");
                              }}
                            >
                              {" "}
                              Instant resolution
                            </AnchorLink>
                          </li>
                        </ul>
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section">
                          <div id="section-1">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  Self-service automation
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  Take self-service a step above general
                                  automation by implementing next-gen technology
                                  and making inroads to providing better
                                  workplace support for the modern workforce.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content ">
                                  Millennials and the new generation workers
                                  (Gen Z) are self-reliant and growing in
                                  number. This tech-savvy generation prefers a
                                  do-it-yourself (DIY) model, and companies
                                  would be wise to use automation and AI on
                                  their internal IT helpdesk to cater to the
                                  millennials in the workplace. For instance,
                                  they would be able to self-check the status of
                                  a ticket or reset a password or check the
                                  status of an incident. This form of
                                  self-service is DIY assistance, which you can
                                  provide with Workativ Assistant.
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="wv-content-section">
                          <section
                            className="benefits_knowledge"
                            id="section-2"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="">
                                  <div className="">
                                    <section className="cards_without_bg">
                                      <h4 className="wv-heading-h4 mb-2">
                                        AI Self-service through Workativ
                                        Assistant
                                      </h4>
                                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                        Workativ Assistant is an intelligent
                                        conversational assistant that helps
                                        employees self- resolve issues without a
                                        slip in productivity. It is powered by
                                        an AI+automation engine, with the
                                        capability to understand the meaning
                                        behind a user’s question. This
                                        capability enables the assistant to
                                        deliver the relevant answers to help
                                        users resolve issues on their own.
                                      </p>

                                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                        A few repetitive requests that can be
                                        answered instantly through Workativ
                                        Assistant
                                      </p>
                                      <div className="full_width_li">
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Report issues
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Reset password
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Access provisioning
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Modify account details
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Verify email
                                          </h4>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>

                        <section className="wv-section-mv-p  content_para">
                          <div id="section-2">
                            <div className="container">
                              <div className="row">
                                <div className="col">
                                  <h4 className="wv-heading-h4 mb-2">
                                    How does Workativ Assistant make your work
                                    more efficient?
                                  </h4>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    With the introduction of a virtual assistant
                                    for workplace support, repetitive and low
                                    priority issues get resolved instantly,
                                    thereby reducing the number of tickets for
                                    helpdesk agents. The benefit is a better
                                    MTTR, which in turn lowers the cost of a
                                    ticket.
                                  </p>

                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    A survey reveals that millennials would
                                    instead use a self-service than pick up the
                                    phone to resolve issues. Automated
                                    resolution on your self-service helps your
                                    employees autonomously resolve issues, no
                                    longer depending on the IT support team to
                                    resolve mundane requests.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
